.bill-footer {
	background-color: #ffe5b5;
	padding: 10px;
	position: fixed;
	bottom: 50px;
	// bottom: 54px;
	// width: 95%;
	@media (orientation: portrait) {
	}
	// @media (orientation: landscape) {

	// }
}

.bill-ftr-child {
	margin-bottom: 7px;
}

.line-bill {
	color: #fff;
	box-shadow: none;
	width: 100%;
	margin-bottom: 0;
	margin-top: 5px;
	border-width: 0;
	height: 2px;
	background-color: #fff;
}

.bill-total {
	margin-top: 12px;
}

.right-info {
	float: right;
	// padding-right: 20px;
}

.item-discount-footer {
	background-color: white;
	padding: 10px;
	position: fixed;
	bottom: 0;
	width: 100%;
}

.qr-scanner-div {
	margin: 0;
	top: 30%;
	// -ms-transform: translateY(30%);
	// transform: translateY(30%);
}
