@import './layout/color.scss';
.fs-8 {
  font-size: 8px;
}

.fs-9 {
  font-size: 9px;
}

.fs-10 {
  font-size: 10px;
  line-height: 12px;
}

.fs-12 {
  font-size: 12px;
  line-height: 14px;
}

.fs-14 {
  font-size: 14px;
  line-height: 16px;
}

.fs-15 {
  font-size: 15px;
}

.fs-16 {
  font-size: 16px;
  line-height: 18px;
}

.fs-18 {
  font-size: 18px;
}

.fs-20 {
  font-size: 20px;
}

.fw-100 {
  font-weight: 100;
}

.fw-200 {
  font-weight: 200;
}

.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 700;
}

.fw-900 {
  font-weight: 700;
}

.fw-thin {
  @extend .fw-200;
}

.fw-extralight {
  @extend .fw-300;
}

.fw-light {
  @extend .fw-400;
}

.fw-regular {
  @extend .fw-500;
}

.fw-medium {
  @extend .fw-600;
}

.fw-semibold {
  @extend .fw-700;
}

.fw-bold {
  @extend .fw-800;
}

.fw-strongbold {
  @extend .fw-800;
}

.fw-extrabold {
  @extend .fw-900;
}

.italic {
  font-style: italic;
}

.c-darkblue {
  color: #001088 !important;
}

.text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.title {
  @extend .fs-16, .fw-semibold, .text-overflow;
}

.smTitle {
  @extend .fs-14, .fw-semibold, .text-overflow;
}

.xsTitle {
  @extend .fs-12, .fw-semibold, .text-overflow;
}

.xxTitle {
  @extend .fs-10, .fw-semibold, .text-overflow;
}

.desc {
  @extend .fs-10, .fw-regular, .text-overflow;
}

.mdDesc {
  @extend .fs-12, .fw-regular, .text-overflow;
}

.label {
  @extend .fs-10, .fw-medium, .text-overflow;
}

.mdLabel {
  @extend .fs-12, .fw-medium, .text-overflow;
}

.value {
  @extend .fs-12, .fw-regular, .text-overflow;
}

.text-separator {
  margin: 0px 4px;
}

.text-noflow {
  white-space: unset;
}

.highlight-text {
  color: $primary-color;
  @extend .fw-semibold;
}

.click-text {
  cursor: pointer;
  text-decoration: underline;
}

.primary-click-text {
  @extend .highlight-text, .click-text;
}

.icon-text {
  display: flex;
  align-items: center;
  overflow: hidden;
  .MuiSvgIcon-root {
    @extend .fs-14;
    margin-right: 4px;
  }
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
// .core-list .desc {
//   color: #454545;
// }
// user listing status
.blue-status {
  color: #0019d8;
  width: 60px;
  font-size: 10px;
  background: #d5dbff;
  text-align: center;
  font-weight: 500;
  margin-left: 8px;
  margin-right: 8px;
}
.orange-status {
  color: #fb6232;
  width: 60px;
  font-size: 10px;
  background: #ffe7cb;
  text-align: center;
  font-weight: 500;
  margin-left: 8px;
  margin-right: 8px;
}
.grey-status {
  color: #454545;
  width: 60px;
  font-size: 10px;
  background: #c2c2c2;
  text-align: center;
  font-weight: 500;
  margin-left: 8px;
  margin-right: 8px;
}

// .xsTitle{
//   .MuiTypoghraphy-root{
//   .MuiListItemText-primary{
//     .MuiTypography-body1{
//       .MuiTypography-displayBlock{
//         .MuiTypography-body1 {
//           font-family: Poppins, sans-serif !important;
//         }
//       }
//     }
//   }
//   }
//   }
