//new changes 5/6/2021 by syafiq ali
.main-header-fix {
	position: relative;
	width: 100%;
	top: -29px;
	z-index: 999;
}
.fix-subheader {
	position: relative;
	bottom: 93px;
	z-index: 100;
}
.wraps-dynamic-content {
	position: absolute;
	width: calc(100% - 77px);
	right: 14px;
	height: 40px;
}

.desktop-header-customer {
	&::before {
		@media screen and (min-width: 600px) {
			content: close-quote;
			width: 100%;
		}
	}
	background-color: unset;
	background: url(../images/header-bg.svg);
	background-position: bottom;
	background-repeat: no-repeat;
	background-size: 130%;

	// &.MuiAppBar-root {
	// 	z-index: 2;
	// 	@media screen and (min-width: 600px) {
	// 		// position: unset;
	// 		box-shadow: none;
	// 		top: 64px;
	// 		width: calc(100% - 288px);
	// 		right: 24px;
	// 	}
	// 	&.full-width {
	// 		left: 24px;
	// 		width: calc(100% - 48px);
	// 	}
	// }

	//new changes 5/6/2021 by syafiq ali

	// &.fix-responsive {
	// 	@media screen and (min-width: 600px) {
	// 		box-shadow: none;
	// 		// position: absolute;
	// 		width: 100%;
	// 		right: -3px;
	// 		top: -24px;
	// 	}
	// }

	.first-btn {
		padding: 0px;
		padding-right: 8px;
		color: #fff;
		border-radius: 0;
		.MuiSvgIcon-root {
			background: #fff;
			color: #ff9800;
			// background: #78a9ff;
			// border-radius: 0px;
			border-radius: 3px; //latest update
			padding: 2px;
		}
	}

	// background-position: center;
	// background-repeat: no-repeat;
	// background-size: cover;
	// background: #ffffff !important;
	// background: linear-gradient(to right, #2f47ff, #2408ba);

	z-index: 2;
	.MuiButtonBase-root {
		padding: 0px 16px;
		margin-left: -16px;
	}
	.title {
		margin-top: -2px;
	}
	.MuiListItemIcon-root {
		min-width: 30px;
	}
	.MuiInputBase-input {
		padding: 0;
	}
	&.MuiPaper-root {
		.MuiToolbar-root {
			min-height: 70px;
		}
	}

	.MuiToolbar-root {
		flex-wrap: wrap;
		min-height: unset;
		padding: 0px 10px;
		.container {
			display: flex;
			width: 100%;
			border-bottom: 1px solid #fff;
			padding-bottom: 4px;
			align-items: center;
			text-align: start;
			.header-text {
				flex: 1;
				.authorizer {
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					font-size: 10px;
					color: #fff;
					font-weight: 600;
					line-height: 8px;
					padding-top: 2px;
				}

				.domain {
					color: #212121;
					line-height: 16px;
					font-size: 12px;
					line-height: 14px;
					font-weight: 600;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					margin-top: 2px;
				}
			}
		}

		.MuiBreadcrumbs-root {
			margin-top: -20px;
			.MuiBreadcrumbs-ol {
				// @extend .overflow-hidden;
				overflow: hidden;

				// line-height: 8px;
				padding-top: 2px;
				margin-right: 2px;
				display: inline-flex;
				flex-wrap: initial;
				.MuiBreadcrumbs-li {
					// @extend .text-overflow;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
				.MuiBreadcrumbs-separator {
					margin: 0px 2px;
					color: #212121;
					font-size: 9px;
					line-height: 1px;
					padding: 12px 0px 5px 0px;
				}
				.breadcrumb-title {
					font-size: 10px;
					font-weight: 500;
					line-height: 16px;
					color: #212121;
				}
				.breadcrumb-title-right {
					font-size: 10px;
					color: #212121;
					font-weight: 600;
					line-height: 16px;
				}
			}
		}
	}
}
