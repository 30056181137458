main {
  width: calc(100% - 300px);
}

html {
  background: #f5f5f5;
}

html,
body {
  min-height: 100%;
  .p-0 {
    padding: 0px;
  }
  .p-10 {
    padding: 10px;
  }
  .p-20 {
    padding: 20px;
  }
  .p-30 {
    padding: 30px;
  }
  .p-l-0 {
    padding-left: 0px;
  }
  .p-l-10 {
    padding-left: 10px;
  }
  .p-l-20 {
    padding-left: 20px;
  }
  .p-r-0 {
    padding-right: 0px;
  }
  .p-r-10 {
    padding-right: 10px;
  }
  .p-r-20 {
    padding-right: 20px;
  }
  .p-r-30 {
    padding-right: 30px;
  }
  .p-r-40 {
    padding-right: 40px;
  }
  .p-t-0 {
    padding-top: 0px;
  }
  .p-t-10 {
    padding-top: 10px;
  }
  html body .p-t-20 {
    padding-top: 20px;
  }
  .p-t-30 {
    padding-top: 30px;
  }
  .p-b-0 {
    padding-bottom: 0px;
  }
  .p-b-5 {
    padding-bottom: 5px;
  }
  .p-b-10 {
    padding-bottom: 10px;
  }
  .p-b-20 {
    padding-bottom: 20px;
  }
  .p-b-30 {
    padding-bottom: 30px;
  }
  .p-b-40 {
    padding-bottom: 40px;
  }
  .m-0 {
    margin: 0px;
  }
  .m-l-5 {
    margin-left: 5px;
  }
  .m-l-10 {
    margin-left: 10px;
  }
  .m-l-15 {
    margin-left: 15px;
  }
  .m-l-20 {
    margin-left: 20px;
  }
  .m-l-30 {
    margin-left: 30px;
  }
  .m-l-40 {
    margin-left: 40px;
  }
  .m-r-5 {
    margin-right: 5px;
  }
  .m-r-10 {
    margin-right: 10px;
  }
  .m-r-15 {
    margin-right: 15px;
  }
  .m-r-20 {
    margin-right: 20px;
  }
  .m-r-30 {
    margin-right: 30px;
  }
  .m-r-40 {
    margin-right: 40px;
  }
  .m-t-0 {
    margin-top: 0px;
  }
  .m-t-2 {
    margin-top: 2px;
  }
  .m-t-5 {
    margin-top: 5px;
  }
  .m-t-10 {
    margin-top: 10px;
  }
  .m-t-15 {
    margin-top: 15px;
  }
  .m-t-20 {
    margin-top: 20px;
  }
  .m-t-30 {
    margin-top: 30px;
  }
  .m-t-40 {
    margin-top: 40px;
  }
  .m-b-0 {
    margin-bottom: 0px;
  }
  .m-b-5 {
    margin-bottom: 5px;
  }
  .m-b-10 {
    margin-bottom: 10px;
  }
  .m-b-15 {
    margin-bottom: 15px;
  }
  .m-b-20 {
    margin-bottom: 20px;
  }
  .m-b-30 {
    margin-bottom: 30px;
  }
  .m-b-40 {
    margin-bottom: 40px;
  }
  .vt {
    vertical-align: top;
  }
  .vm {
    vertical-align: middle;
  }
  .vb {
    vertical-align: bottom;
  }
  .b-r {
    border-right: 1px solid rgba(120, 130, 140, 0.13);
  }
  .b-l {
    border-left: 1px solid rgba(120, 130, 140, 0.13);
  }
  .b-b {
    border-bottom: 1px solid rgba(120, 130, 140, 0.13);
  }
  .b-t {
    border-top: 1px solid rgba(120, 130, 140, 0.13);
  }
  .b-all {
    border: 1px solid rgba(120, 130, 140, 0.13);
  }
  .overflow-hidden {
    overflow: hidden;
  }
}

.snackbar .MuiSnackbarContent-root {
  color: rgba(255, 255, 255, 0.87);
  background-color: rgb(50, 50, 50);
  font-weight: 500;
}

@media (min-width: 600px) {
  .MuiSnackbar-anchorOriginBottomCenter.snackbar {
    left: 60%;
  }
  .MuiSnackbar-anchorOriginBottomLeft.snackbar {
    left: 26%;
  }
}

.flex-space {
  flex: 1;
}

.content-wrapper {
  padding: 8px 8px;
  @media screen and (min-width: 600px) {
    margin-top: 57px;
  }

  &.with-footer {
    padding-bottom: 82px;
  }
  &.with-footer-n-float {
    padding-bottom: 120px;
  }
  &.with-float {
    padding-bottom: 78px;
  }
  &.with-longFooter {
    padding-bottom: 350px;
  }
  // &.with-float-amt {
  //   padding-bottom: 100px;
  // }
  &.with-tab {
    margin-top: 0px;
    @media screen and (min-width: 600px) {
      margin-top: 0px !important;
    }
  }
  &.with-search {
    margin-top: 40px;
    @media screen and (min-width: 600px) {
      margin-top: 100px !important;
    }
  }
  &.multiple-dynamicInfo {
    margin-top: 53px;
    @media screen and (min-width: 600px) {
      margin-top: 55px !important;
    }
  }
  &.customer-dynamicInfo {
    margin-top: 30px;

    @media screen and (min-width: 600px) {
      margin-top: 11px !important;
    }
  }
  &.single-dynamicInfo {
    margin-top: 50px;
    @media screen and (min-width: 600px) {
      margin-top: 115px !important;
    }
  }
  &.dynamicinfo-search {
    margin-top: 76px;
    @media screen and (min-width: 600px) {
      margin-top: 146px !important;
    }
  }
  &.multi-dynamicinfo-search {
    margin-top: 105px;
    @media screen and (min-width: 600px) {
      margin-top: 180px !important;
    }
  }
  //new changes 5/6/2021 by syafiq ali

  &.with-bigSubHeaderNSearchBar {
    margin-top: 96px;
    @media screen and (min-width: 600px) {
      margin-top: 120px !important;
    }
  }
  &.onlyContentScrollable {
    // padding-bottom: 60px;
    @media screen and (min-width: 600px) {
      // padding-bottom: 25px;
      overflow: auto;
      width: 100%;
      position: relative;
      right: 5px; // new changes 10/6/2021 by syafiq ali
    }
  }

  &.multi-line-subheader {
    margin-top: 65px;

    @media screen and (min-width: 600px) {
      margin-top: 130px;
    }
  }

  &.datePicker-search {
    margin-top: 95px;
    @media screen and (min-width: 600px) {
      margin-top: 170px !important;
    }
  }

  &.searchBar-dropdownFilter {
    margin-top: 40px;
    @media screen and (min-width: 600px) {
      margin-top: 100px !important;
    }
  }

  &.table-header {
    width: 96%;
    position: fixed;
    z-index: 1;
    background: #f5f5f5;

    @media screen and (min-width: 600px) {
      width: calc(100% - 50px);
      right: 14px;
      transition: width 0.2s;
      top: 92px;
    }
  }
  &.table-header-fw {
    width: 96%;
    position: fixed;
    z-index: 1;
    background: #f5f5f5;
    @media screen and (min-width: 600px) {
      width: calc(100% - 288px);
      right: 14px;
      transition: width 0.2s;
      top: 92px;
    }
  }
  &.auditLog-subheader-search {
    margin-top: 139px;
    @media screen and (min-width: 600px) {
      margin-top: 205px !important;
    }
  }
}

.empty-listing {
  text-align: center;
  padding: 50px 12px;
  z-index: 40;
  position: relative;
  img {
    margin-bottom: 12px;
  }
  .smTitle {
    margin-bottom: 6px;
    white-space: unset;
  }
  .desc {
    white-space: unset;
  }
}

.remove-padding {
  padding: 0px !important;
  box-shadow: none !important;
}

.MuiButtonBase-root.Mui-disabled {
  background: #e8e8e8;
}

.menu-list {
  .MuiMenuItem-root {
    font-size: 14px;
  }
}

.fixed-search-header {
  position: fixed !important;
  width: 100%;
  z-index: 250;

  &.single-info {
    top: 40px;
    @media screen and (min-width: 600px) {
      width: 100%;
      top: 60px;
    }
  }
  &.with-dynamic {
    top: 81px;
    @media screen and (min-width: 600px) {
      width: 100%;
      // position: fixed !important;
      // z-index: 100;
      top: 81px;
    }
  }
  &.with-dynamic-multiInfo {
    top: 55px;
    @media screen and (min-width: 600px) {
      width: 100%;
      // position: fixed !important;
      // z-index: 100;
      top: 55px;
    }
  }
  &.with-dynamic-multiSubInfo {
    top: 138px;
    @media screen and (min-width: 600px) {
      width: calc(100% - 288px);
      // position: fixed !important;
      // z-index: 100;
      top: 228px;
    }
  }
  &.with-date-picker {
    top: 123px;
    @media screen and (min-width: 600px) {
      // width: 94%;
      top: 210px;
    }
  }
  @media screen and (min-width: 600px) {
    // width: calc(100% - 288px);
    width: calc(100% - 48px);
    top: 155px;
  }
}

.fixed-search-header-fw {
  position: fixed !important;
  width: 100%;
  z-index: 250;
  top: 69px;

  &.single-info {
    top: 40px;
    @media screen and (min-width: 600px) {
      width: 100%;
      top: 60px;
    }
  }
  &.with-dynamic {
    top: 81px;
    @media screen and (min-width: 600px) {
      width: 100%;
      // position: fixed !important;
      // z-index: 100;
      top: 81px;
    }
  }
  &.with-dynamic-multiInfo {
    top: 55px;
    @media screen and (min-width: 600px) {
      width: 100%;
      // position: fixed !important;
      // z-index: 100;
      top: 55px;
    }
  }
  &.with-dynamic-multiSubInfo {
    top: 138px;
    @media screen and (min-width: 600px) {
      width: calc(100% - 288px);
      // position: fixed !important;
      // z-index: 100;
      top: 228px;
    }
  }
  &.with-date-picker {
    top: 123px;
    @media screen and (min-width: 600px) {
      // width: 94%;
      top: 210px;
    }
  }
  @media screen and (min-width: 600px) {
    width: calc(100% - 288px);
    top: 155px;
  }
}

.full-width {
  left: 24px;
  width: calc(100% - 48px);
  //new changes 5/6/2021 by syafiq ali
  /* width */
  ::-webkit-scrollbar {
    width: 0px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.contact-input-new.react-tel-input {
  margin-bottom: 6px;
  margin-top: 16px;
  width: 100%;
  .special-label {
    color: #878787 !important;
    background: transparent;
    margin-left: 0px;
  }

  .form-control {
    width: 100%;
    background: transparent;
    border-color: transparent;
    color: black !important;
    border-bottom: 1px solid #0000006b !important;
    padding: 12px 0px 12px 50px;
    border-radius: unset;
  }

  .country-list {
    max-height: 110px !important;
  }

  .special-label {
    font-size: 12px;
  }

  .invalid-number-message {
    left: -4px;
    top: 47px;
    color: #d32f2f;
    font: 400 0.75rem/1.66 Poppins, sans-serif;
  }

  .form-control:focus {
    box-shadow: unset;
    outline: none;
    border-color: #ffffff;
  }
}

.contact-input-auth.react-tel-input {
  margin-bottom: 6px;
  margin-top: 16px;
  width: 100%;
  .special-label {
    color: #878787 !important;
    background: transparent;
    margin-left: -26px;
  }
  .form-control {
    // border: 0px;
    width: 100%;
    background: transparent;
    border-color: transparent;
    color: #ffffff !important;
    border-bottom: 1px solid #878787 !important;
    padding: 12px 0px 12px 50px;
    border-radius: unset;
  }

  .country-list {
    max-height: 110px !important;
  }

  .special-label {
    font-size: 12px;
  }

  .invalid-number-message {
    left: -4px;
    top: 47px;
    color: #d32f2f;
    font: 400 0.75rem/1.66 Poppins, sans-serif;
  }

  .form-control:focus {
    box-shadow: unset;
  }
}
