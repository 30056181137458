.message-alert {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  padding: 8px 12px;
  //   width: 100%;
  margin-bottom: 4px;
  background: #ffe5b5;
  align-items: center;
  display: flex;

  .icon img {
    width: 20px;
    margin-right: 6px;
  }
}

.message-alert-icon {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  padding: 1px 3px;
  //   width: 100%;
  margin-bottom: 0px;
  background: #ffe5b5;
  align-items: center;
  display: flex;

  .icon img {
    width: 20px;
    margin-right: 6px;
  }
}
